import React from 'react'

import styles from "./leaderdetails-css-module.module.scss"
// import avatar from "../../../../static/avatar.png"
// import sharad from "../../../assets/leadership/sharad.png"
// import akash from "../../../assets/leadership/akash.png"
// import subhashani from "../../../assets/leadership/subhashani.png"
// import anil from "../../../assets/leadership/anil.png"
// import imran from "../../../assets/leadership/imran.png"
// import dennis from "../../../assets/leadership/dennis.png"
import amit from "../../../assets/leadership/amit.png"
// import gaurav from "../../../assets/leadership/Gaurav.jpg"
// import meher from "../../../assets/leadership/meher.png"
// import leftArrow from "../../../../static/leftArrow.svg"
// import rightArrow from "../../../../static/rightArrow.svg"
import LeadershipTeam from './LeadershipTeam'

const LeaderDeatils = (props) => {
    let leaderImg = {
        leader_1: amit
    }
    let leader;
    if (props.name === "") {
        leader = LeadershipTeam[0];
    } else {
        leader = LeadershipTeam.filter((leader) => leader.name === props.name)[0];
    }
    return (
        <>
            <div className={styles.leadershipContentCenter}>
                <div className={styles.leftArrow}>
                    <div className={styles.navigation_left} onClick={props.navigateLeft}>
                        {/* <img  src={leftArrow} alt="logo" /> */}
                        {/* <span onClick={props.navigateLeft}> &#60;</span> */}
                    </div>
                </div>
                <div className={styles.content}>
                    <div className={[styles.leader_name, styles.navigation_left].join(" ")}>
                        <span>{leader.name}</span>
                    </div>
                    <div className={[styles.leader_designation, styles.navigation_left].join(" ")}>
                        <span>{leader.designation}</span>
                    </div> 

                    <div className={styles.modalFlex}>  
                        <div className={styles.modalMobileNav}>
                            <div className={styles.navigation_left_mob} onClick={props.navigateLeft}>
                                {/* <img  src={leftArrow} alt="logo" /> */}
                            </div>
                            <div style={{width:'100%'}}>
                                <img className={styles.leader_image} src={leaderImg[leader.path]} alt="logo" />
                                <div className={[styles.leader_name, styles.navigation_left_mob].join(" ")}>
                                    <span>{leader.name}</span>
                                </div>
                                <div className={[styles.leader_designation, styles.navigation_left_mob].join(" ")}>
                                    <span>{leader.designation}</span>
                                </div> 
                            </div>
                            <div className={styles.navigation_right_mob} onClick={props.navigateRight}>
                                {/* <img  src={rightArrow} alt="logo" /> */}
                            </div>
                        </div>
                                        
                        <div className={styles.leader}>
                            <div className={styles.leader_bio}>
                                { leader['bio'].map((leader_bio, i) => (
                                     <div key={i}> {leader_bio} </div>
                                )) }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.rightArrow}>
                    <div className={styles.navigation_right} onClick={props.navigateRight}>
                        {/* <img  src={rightArrow} alt="logo" /> */}
                        {/* <span onClick={props.navigateRight}>&#62;</span> */}
                    </div>
                </div>

            </div>
        </>
    )
}

export default LeaderDeatils